<template>
    <div>
        <el-input 
                class="l-input-icon"
                :placeholder="$t(placeholder)"
                v-model="value2"
                :size="size"
                ref="main"
                :clearable="disabled?false:clearable"
                :disabled="disabled"
                @focus="handleShow">
        <span slot="append"
                @click="handleShow">
              <el-button slot="append" :icon="value2"></el-button>
        </span>
        </el-input>
        <el-dialog class="l-dialog"
                :title="$t(placeholder)"
                append-to-body
                :visible.sync="box"
                width="80%"
                :top="top + 'px'">
        <div class="l-auto-window" :style="{height:height + 'px',width:'100%',padding:'8px 8px 0 8px'}" >
            <el-tabs v-model="activeName" type="card" >
                <template v-for="(item,index) in iconList" >
                    <el-tab-pane :key="index" :label="item.label" :name="'l_' + index" style="padding-top:8px;" >
                        <template v-for="(icon,index2) in item.list" >
                            <div :key="index2" class="l-input-icon-item" >
                                <el-button size="small" :icon="icon" circle @click="handleSubmit(icon)" ></el-button>
                            </div>
                        </template>
                    </el-tab-pane>
                </template>
            </el-tabs>
        </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name:'l-input-icon',
  props: {
    value:{},
    placeholder:{
      type:String,
      default:'请选择'
    },
    size:String,
    disabled:Boolean,
    clearable:{
      type:Boolean,
      default:true
    },
  },
  data () {
    return {
        box:false,
        activeName:'l_0',
        text:''
    };
  },
  mounted () {
  },
  computed:{
    value2:{
      get(){
        return this.value || this.text;
      },
      set(val){
        this.text = val;
        this.$emit('input', val);
        this.$emit('change', val);
      }
    },
    height(){
        let height = 560;
        if(height + 40 > document.body.clientHeight){
            height = document.body.clientHeight - 32 - 40;   
        }
        return height;
    },
    top(){
        return (document.body.clientHeight - this.height)/2
    },
    iconList(){
        if(this.lr_icons){
          return this.lr_icons
        }
        else{
          return []
        }
    }
  },
  methods:{
    handleShow () {
      this.$refs.main.blur();
      if (this.disabled || this.readonly) return;
      this.box = true;
    },
    handleSubmit (item) {
      this.box = false;
      this.value2 = item;
    }
  }

}
</script>
<style lang="less">
@import './index.less';
</style>
