<template>
    <l-data-panel :label="label">
        <div class="l-data-list l-rblock" >
            <template v-if="value.length > 0" >
                <div class="l-data-list--line" v-for="(item,index) in value" :key="index" >
                    <div class="l-data-list--text">{{item[textKey]}}</div>
                    <div class="l-data-list--time">{{formatDate(item[timeKey])}}</div>
                </div>
            </template>
            <el-empty v-else description="暂无数据" :image-size="64"></el-empty>
        </div>
    </l-data-panel>
</template>
<script>
export default {
    name:'l-data-list',
    props: {
        value:{
            type:Array,
            default:()=>[]
        },
        label:String,
        textKey:{
            type:String,
            default:'text'
        },
        timeKey:{
            type:String,
            default:'time'
        },
        timeFormat:{
            type:String,
            default:'yyyy-MM-dd hh:mm:ss'
        }
    },
    data(){
        return {

        }
    },
    computed:{

    },
    methods:{
        formatDate(v){
            return this.$formatDate(v,this.timeFormat)
        }
    }
}
</script>

<style lang="less">
@import './index.less';
</style>
