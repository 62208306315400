<template>
    <div>
        <el-input
                ref="main"
                :placeholder="$t(placeholder)"
                v-model="showValue"
                :size="size"
                :clearable="disabled?false:clearable"
                :disabled="disabled"

                @clear="handleInputClear"
                @focus="handleShow">
            <span slot="append"
                    @click="handleShow">
                <el-button slot="append" icon="el-icon-more"></el-button>
            </span>
        </el-input>
        <l-dialog 
            :title="$t(placeholder)"
            :visible.sync="dialogVisible"
            :height="height"
            :width="width"

            :hasBtns="multiple"

            @ok="handleSelectMultiple"
            @opened="handleDialogOpened"
            >
            <l-layout class="l-tab-page">
                <l-select-panel
                    v-model="value2"
                    ref="selectPanel"
                    :valueKey="valueKey"
                    model="client"
                    :columns="myColumns"
                    :selectedData.sync="selectedData"
                    :multiple="multiple"
                    :isPage="isPage"

                    :loadSelectTable="loadSelectTable"
                    @rowClick="handleSelectPanel"
                    style="padding:8px;"
                >
                </l-select-panel>
            </l-layout>
        </l-dialog>
    </div>
</template>
<script>

export default {
    name:'l-layer-select',
    props: {
        value:{},
       
        placeholder:{
            type:String,
            default:'请选择'
        },
        size:String,
        disabled:{
            type:Boolean,
            default:false
        },
        clearable:{
            type:Boolean,
            default:true
        },
        height:{
            type:Number,
            default:504
        },
        width:{
            type:Number,
            default:960
        },
        multiple:{
            type:Boolean,
            default:false
        },
        isPage:{
            type:Boolean,
            default:true
        },
        columns:{
            type:Array,
            default:() => []
        },
        options:{
            type:Array,
            default:()=>[]
        },
        labelKey:{
            type:String,
            default:'label'
        },
        valueKey:{
            type:String,
            default:'value'
        }
    },
    data () {
        return {
            dialogVisible:false,
            selectedData:[],
            value2:''
        }
    },
    computed:{
        showValue:{
            get(){
                if(this.$validatenull(this.value)){
                    return ''
                }
                const value = this.value + ''
                const valueList = value.split(',')
                const res = []
                valueList.forEach(item=>{
                    const v = this.options.find(t=>t[this.valueKey] == item)
                    if(v){
                        res.push(v[this.labelKey])
                    }
                })
                return String(res)
            },
            set(val){
                this.$emit('input',val)
            }
        },
        myColumns(){
            return this.columns.filter(t=>!t.hidden)
        }
    },
    mounted () {

    },
    watch:{
        value:{
            handler(val){
                this.value2 = val
            },
            immediate:true
        }
    },
    methods:{
        handleInputClear(){
            this.$refs.selectPanel.handleClear()
            this.handleChange()
        },
        handleChange(val){
            this.$emit('change',val)
        },
        handleShow(){
            if(!this.disabled){
                this.dialogVisible = true
            }
        },

        tableLoadData(){
            this.$refs.selectPanel.init()
        },
        loadSelectTable(postData){
            return new Promise((resolve) => {
                if(this.options.length == 0){
                     resolve({rows:[]})
                }
                else{
                    const data = this.options.filter(t=>{
                        let res = false
                        this.myColumns.forEach(col=>{
                            const item = (t[col.prop] || '') + ''
                            if(item.indexOf(postData.keyword) != -1){
                                res = true
                                return false
                            }
                        })
                        return res
                    })
                    if(this.isPage){
                        const rows =  this.$pagination(postData.page,postData.rows,data)

                        resolve({rows:rows,records:data.length})
                    }
                    else{
                        resolve({rows:data})
                    }
                }
            })
        },

        handleDialogOpened(){
            this.tableLoadData()
        },
        handleSelectPanel(row){
            this.$emit('input',this.value2)
            this.$refs.main.focus()
            this.$refs.main.blur()
            this.dialogVisible = false

            this.handleChange(row)
        },
        handleSelectMultiple(){
            this.$emit('input',this.value2)
            this.$refs.main.focus()
            this.$refs.main.blur()
            this.dialogVisible = false
        }
    }
}
</script>