<template>
    <div class="l-code-mirror">
        <textarea ref="code"></textarea>
    </div>
</template>
<script>
export default {
    name:'l-code-mirror',
    props: {
        value:{},
        mode:{
            type:String,
            default:'application/javascript'
        },
        readOnly:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        value:{
            handler(val){
                if(val != this.value2){
                    this.value2 = val
                    this.editor && this.editor.setValue(val)
                    setTimeout(()=>{
                        this.editor && this.editor.refresh()
                    })
                }
            },
            immediate:true
        },
        mode:{
            handler(val){
                this.editor && this.editor.setOption("mode",val)
            }
        }
    },
    data(){
        return{
            editor:null,
            value2:''
        }
    },
    computed:{
    },
    mounted(){
        this.init()
    },
    beforeDestroy(){
        this.editor.off("change")
        this.editor = null
    },
    methods:{
        init(){
            this.editor = window.CodeMirror.fromTextArea(this.$refs.code, {
                lineNumbers: true,
                styleActiveLine: true,
                matchBrackets: true,
                readOnly:this.readOnly,
                mode:this.mode
            })
            this.value2 = this.value
            this.editor.setValue(this.value)
           
            this.editor.on("change",()=>{
                const value = this.editor.getValue()
                this.value2 = value
                this.$emit('input',value)
            })
        }
    }
}
</script>
<style lang="less">
    .l-code-mirror{
        position: relative;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        .CodeMirror{
            height: 100%;
            width: 100%;
        }
    }
</style>