<template>
    <div class="l-data-board l-rblock"  v-loading="loading" >
        <div :class="['l-data-board__icon',themeType[theme]?themeType[theme]:themeType[0]]" >
            <i :class="icon"></i>
        </div>
        <div class="l-data-board__text" >
            <div class="l-data-board__num" ><l-count-up :end="value" ></l-count-up></div>
            <div class="l-data-board__title" >{{label}}</div>
        </div>
    </div> 
</template>
<script>
export default {
    name:'l-data-board',
    props: {
        value:[Number,String],
        icon:String,
        label:String,
        theme:{
            type:Number,
            default:0
        },
        loading:{
            type:Boolean,
            default:false
        },
    },
    data(){
        return {
            themeType:['theme1','theme2','theme3','theme4']
        }
    },
    computed:{
        
    }
}
</script>

<style lang="less">
@import './index.less';
</style>
