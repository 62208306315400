<template>
  <el-table-column
    :fixed="isNotFixed? false: columnOption.fixed"
    :prop="columnOption.prop"
    :label="columnOption.label"
    :width="columnOption.width"
    :min-width="columnOption.minWidth"
    :resizable="columnOption.resizable"
    :align="columnOption.align"
    :header-align="columnOption.headerAlign"
    :show-overflow-tooltip="columnOption.isNotAutoWrap == true?true:false"
  >
    <template  v-slot:default="scope" >
        <slot v-bind:scope="scope" >{{columnText(scope.$index,scope.row,columnOption,scope)}}</slot>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "dynamic-column",
  props: {
    columnOption: {
      type: Object,
      required: true
    },
    isNotFixed:Boolean
  },
  mounted () {
  },
  methods:{
    columnText(index,row,column,scope) {
        if(typeof column.formatter == 'function'){
          return column.formatter({
            row:row,column:scope.column,cellValue:row[column.prop],index:index
          });
        }
        else{
          return row[column.prop];
        }
    }
  }
};
</script>
