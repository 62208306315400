<template>
    <div class="l-rblock" >
        <l-tree-select
            v-if="isLoadByCompany"
            v-model="selectValue"
            :options="departmentOptions"
            :placeholder="$t(placeholder)"
            :size="size"
            :clearable="clearable"
            @change="handleChange"
        >
        </l-tree-select>
        <el-input
                v-else
                ref="main"
                :placeholder="$t(placeholder)"
                v-model="showValue"
                :size="size"
                :clearable="disabled?false:clearable"
                :disabled="disabled"
                @clear="handleInputClear"
                @focus="handleShow">
            <span slot="append"
                    @click="handleShow">
                <el-button slot="append" icon="fa fa-sitemap"></el-button>
            </span>
        </el-input>
        <l-dialog 
            v-if="!isLoadByCompany"
            :title="$t(placeholder)"
            :visible.sync="dialogVisible"
            :height="480"
            :hasBtns="false"
            width="600px"
            >
            <l-layout class="l-tab-page" :left="300">
                <template #left  > 
                    <l-panel :title="$t('公司信息')" style="padding-right:0;" >
                        <el-tree :data="companyTree" default-expand-all :expand-on-click-node="false"  @node-click="handleCompanyClick">
                            <span class="lr-tree-node"  slot-scope="{ node}">
                                <i class="fa fa-sitemap"></i>
                                {{ $t(node.label) }}
                            </span>
                        </el-tree>
                    </l-panel>
                </template>
                <l-panel :title="$t('部门信息')" style="padding-left:0;" >
                    <el-tree :data="departmentsTree" default-expand-all :expand-on-click-node="false"  @node-click="handleDepartmentClick">
                        <span class="lr-tree-node"  slot-scope="{node}">
                            <i class="fa fa-sitemap"></i>
                            {{$t(node.label)}}
                        </span>
                    </el-tree>
                </l-panel>
            </l-layout>
        </l-dialog>
    </div>
</template>

<script>

export default {
    name:'l-department-select',
    props: {
        value:{},
        size:String,
        placeholder:{
            type:String,
            default:'请选择'
        },
        companyId:{
            type:String,
            default:''
        },
        isLoadByCompany:{
            type:Boolean,
            default:false
        },
        disabled:{
            type:Boolean,
            default:false
        },
        clearable:{
            type:Boolean,
            default:true
        }
    },
    data () {
        return {
            dialogVisible:false,
            selectCompanyId:'',

            text:''
        }
    },
    computed:{
        selectValue:{
            get(){
                return this.value
            },
            set(val){
                this.$emit('input',val)
            }
        },
        departmentOptions(){
            if(this.lr_departments){
                return this.lr_departmentTree(this.lr_departments[this.companyId])
            }
            else{
                return []
            }
        },
    

        showValue:{
            get(){
                if(this.lr_department){
                    return (this.lr_department[this.value] || {}).f_FullName || ''
                }
                else{
                    return ''
                }
                
            },
            set(val){
                this.$emit('input',val)
            }
        },
        companyTree(){
            if(this.lr_companyTree){
                return this.lr_companyTree
            }
            else{
                return []
            }
        },
        departmentsTree(){
            if(this.lr_departments){
                return this.lr_departmentTree(this.lr_departments[this.selectCompanyId])
            }
            else{
                return []
            }
        }
    },
    created(){
        this.lr_loadCompanys && this.lr_loadCompanys()
    },
    watch:{
        value:{
            handler(newVal){
                if(!this.$validatenull(newVal)){
                    this.lr_loadDepartment && this.lr_loadDepartment(newVal)
                }
            },
            immediate: true
        }
    },
    methods:{
        handleChange(val){
            this.$emit('change',val)
        },

        handleShow(){
            this.dialogVisible = true
        },
        handleCompanyClick(node){
            this.lr_loadDepartments && this.lr_loadDepartments(node.f_CompanyId)
            this.selectCompanyId = node.f_CompanyId
        },
        handleDepartmentClick(node){
            this.lr_loadDepartment && this.lr_loadDepartment(node.f_DepartmentId)
            this.selectValue = node.f_DepartmentId
            this.handleChange(node)
            this.dialogVisible = false
        },
        handleInputClear(){
            this.handleChange()
        }
    }
}
</script>