<template>
    <div class="l-data-panel l-rblock">
        <div class="l-data-panel--title" >
            <span class="l-data-panel--label" >{{label}}</span>
        </div>
        <div class="l-rblock" >
            <slot></slot>
        </div>
    </div> 
</template>
<script>
export default {
    name:'l-data-panel',
    props: {
        label:String,
    },
    data(){
        return {
        }
    },
    computed:{
    }
}
</script>

<style lang="less">
@import './index.less';
</style>
