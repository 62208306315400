<template>
    <el-input :value="value2" readonly="readonly" suffix-icon="fa fa-sitemap" ></el-input>    
</template>
<script>
export default {
    name:'l-department',
    props: {
        value:String
    },
    watch: {
        value:{
            handler(newVal){
                if(this.$validatenull(newVal) && this.loginInfo && this.loginInfo.f_DepartmentId){
                    this.lr_loadDepartment && this.lr_loadDepartment(this.loginInfo.f_DepartmentId)
                    this.value2 = this.loginInfo.f_DepartmentId
                }
            },
            immediate: true
        }
    },
    computed:{
        value2:{
            get(){
                if(this.$validatenull(this.value)){
                    return ''
                }
                if(this.lr_department){
                    return (this.lr_department[this.value] || {}).f_FullName || ''
                }
                else{
                    return ''
                }
                
            },
            set(val){
                this.$emit('input',val)
            }
        }
    }
}
</script>