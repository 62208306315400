<template>
<el-dialog
        :visible="midVisible"
        :append-to-body="true"
        custom-class="l-fullscreen-dialog"
        :fullscreen="true"
        :show-close="false"
        width="100%"

        @close="closeDialog"
        @closed="closedDialog"
        @open="openDialog"
        @opened="openedDialog"
        >
        <div class="l-rblock"
          style="padding-top: 57px;"
          v-loading="loading"
          :element-loading-text="$t(loadingText)"  >
          <div class="l-fullscreen-dialog--header" >
              <div class="l-fullscreen-dialog--title" >
                  <img class="l-fullscreen-dialog--logo"  :src="`${rootUrl}img/admin/logo2.svg`" />
                  <p class="l-fullscreen-dialog--title-text" >{{title}}</p>
              </div>
              <div class="l-fullscreen-dialog--mid" :style="{width:headerMidWidth + 'px'}" >
                  <el-steps v-if="isStep" :active="stepActive" simple finish-status="success">
                    <el-step v-for="(item,index) in steps" :key="index" :title="$t(item)"></el-step>
                  </el-steps>
                  <slot name="headerMid" ></slot>
              </div>
              <div class="l-fullscreen-dialog--right" >
                  <el-button v-if="isStep" @click="prev" size="mini" :disabled="stepPrevBtn" >{{$t('上一步')}}</el-button>
                  <el-button v-if="isStep" @click="next" size="mini" :disabled="stepNextBtn" :loading="nextBtnLoading"  >{{$t('下一步')}}</el-button>
                  <slot name="headerRight"></slot>
                  <el-button size="mini" v-if="showOk" type="primary" @click="handleOk" :disabled="stepSaveBtn && isStep" >{{$t(okLabel)}}</el-button>
                  <el-button size="mini" v-if="showClose" @click="handleClose" type="danger">{{$t('关闭')}}</el-button>
              </div>
          </div>
          <div class="l-fullscreen-dialog--body" >
              <slot></slot>
          </div>
        </div>
</el-dialog>
</template>

<script>
export default {
  name:'l-fullscreen-dialog',
  props: {
    visible:{
      type:Boolean,
    },
    title:String,
    headerMidWidth:{
        type:Number,
        default:480
    },
    showClose:{
      type: Boolean,
      default: true
    },
    showOk:{
      type: Boolean,
      default: true
    },
    okLabel:{
      type: String,
      default: '保存'
    },
    
    isStep:{
      type:Boolean,
      default:false
    },
    stepActive:{
      type:Number,
      default:0
    },
    steps:{
      type:Array,
      default:()=>[]
    },
    validateSteps:Function,


  },
  data () {
    return {
      loading: false,
      loadingText:'',
      midVisible:false,
      nextBtnLoading:false,

      stepPrevBtn:true,
      stepNextBtn:false,
      stepSaveBtn:true,
    }
  },
  watch:{
    visible: {
        handler (n) {
            this.midVisible = n
        }
    },
  },
  created () {
  },
  mounted () {
  },
  computed:{
    myStepActive:{
      get(){
        return this.stepActive
      },
      set(val){
        this.$emit('update:stepActive',val)
      }
    }
  },
  methods:{
    // 带步骤的表单
    prev(){
      if(this.myStepActive > 0){
          this.myStepActive--
      }
      this.$nextTick(()=>{
        this.updateBtnState()
      })
    },
    async next(){
      if(await this.myValidateSteps()){
        if(this.myStepActive < (this.steps.length-1)){
          this.myStepActive++
        }
        this.$nextTick(()=>{
          this.updateBtnState()
        })
      }
    },
    updateBtnState(){
      this.stepSaveBtn = true
      this.stepNextBtn = true
      this.stepPrevBtn = true
      switch(this.myStepActive){
        case 0:
          this.stepNextBtn = false
          break;
        case this.steps.length-1:
          this.stepPrevBtn = false
          this.stepSaveBtn = false
          break;
        default:
          this.stepPrevBtn = false
          this.stepNextBtn = false
          break;
      }
    },
    async myValidateSteps(){
      this.nextBtnLoading = true
      if(this.validateSteps){
        const res = await this.validateSteps(this.stepActive)
        this.nextBtnLoading = false
        return res
      }
      this.nextBtnLoading = false
      return true
    },

    handleClose(){
        this.midVisible = false
        //this.$emit('update:visible',false);
    },
    handleOk(){
      this.$emit('ok',this.showLoading,this.hideLoading);
    },
    showLoading(text){
      this.loadingText = text || '保存数据中...';
      this.loading = true
    },
    hideLoading(){
      this.loading = false
    },


    closeDialog() {
      this.$emit('update:visible',false)
      this.$emit('close')
    },
    closedDialog(){
      if(this.isStep){
        this.myStepActive = 0
        this.$nextTick(()=>{
          this.updateBtnState()
        })
      }
      this.$emit('closed')
    },
    openDialog(){
      this.$emit('open')
    },
    openedDialog(){
      this.$emit('opened',this.showLoading,this.hideLoading)
    },
    reset(){
        this.myStepActive = 0
        this.$nextTick(()=>{
          this.updateBtnState()
        })
    } 
  }
}
</script>

<style lang="less">
  @import './index.less';
</style>