<template>
    <el-input :value="value" readonly="readonly" ></el-input>    
</template>

<script>
export default {
    name:'l-guid',
    props: {
        value:{},
    },
    watch: {
        value:{
            handler(newVal){
                if(this.$validatenull(newVal)){
                    const v = this.$uuid()
                    this.$emit('input',v)
                }
            },
            immediate: true
        }
    },
    data () {
        return {
        }
    }
}
</script>