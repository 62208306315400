<template>
  <div class="l-layout" :style="{'padding-left':leftWidth}">
      <div class="l-layout--left" :style="{width:leftWidth}" >
        <div class="l-layout--wrapper" ><slot name="left"></slot></div>
        <div v-if="leftMove" class="l-layout--move" @mousedown="onMousedown('left',$event)" ></div>
      </div>
      <div class="l-layout--container" :style="{'padding-right':rightWidth}" >
        <div class="l-layout--right"  :style="{ width:rightWidth}">
          <div class="l-layout--wrapper" ><slot name="right"></slot></div>
          <div v-if="rightMove" class="l-layout--move" @mousedown="onMousedown('right',$event)" ></div>
        </div>
        <div class="l-layout--container" :style="{'padding-bottom':bottomHight}" >
           <div class="l-layout--bottom" :style="{height:bottomHight}" >
              <div class="l-layout--wrapper" > <slot name="bottom"></slot></div>
              <div v-if="bottomMove" class="l-layout--move" @mousedown="onMousedown('bottom',$event)" ></div>
            </div>
            <div class="l-layout--container" :style="{'padding-top':topHight}"  >
              <div class="l-layout--top" :style="{height:topHight}" >
                <div class="l-layout--wrapper" ><slot name="top"></slot></div>
                <div v-if="topMove" class="l-layout--move" @mousedown="onMousedown('top',$event)" ></div>
              </div>
              <div class="l-layout--wrapper" ref="mid">
                  <slot></slot>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name:'l-layout',
  props: {
    left: {
      type: Number,
      default: 200
    },
    leftMove: {
      type: Boolean,
      default: true
    },
    right: {
      type: Number,
      default: 200
    },
    rightMove:{
      type: Boolean,
      default: true
    },
    top: {
      type: Number,
      default: 60
    },
    topMove:{
      type: Boolean,
      default: true
    },
    bottom:{
      type: Number,
      default: 60
    },
    bottomMove:{
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      mleft:this.left,
      mright:this.right,
      mtop:this.top,
      mbottom:this.bottom,

      move:{
        type:'',
        isMove:false,
        pageX:0,
        pageY:0,
        size:0,
        h:0,
        w:0,
      }
    };
  },
  mounted () {
  },
  watch:{
    left(val){
      this.mleft = val;
    },
    right(val){
      this.mright = val;
    },
    top(val){
      this.mtop = val;
    },
    bottom(val){
      this.mbottom = val;
    }
  },
  computed:{
    leftWidth:function(){
      if(this.$slots.left){
        return this.mleft + 'px'
      }
      else{
        return '0'
      }
    },
    rightWidth:function(){
      if(this.$slots.right){
        return this.mright + 'px'
      }
      else{
        return '0'
      }
    },
    topHight:function(){
      if(this.$slots.top){
        return this.mtop + 'px'
      }
      else{
        return '0'
      }
    },
    bottomHight:function(){
      if(this.$slots.bottom){
        return this.mbottom + 'px'
      }
      else{
        return '0'
      }
    }
  },
  methods:{
    onMousedown:function(type,e){
      this.move.type = type;
      this.move.isMove = true;
      this.move.pageX = e.pageX;
      this.move.pageY = e.pageY;
      this.move.size = this["m"+type];

      this.move.h = this.$refs.mid.clientHeight;
      this.move.w = this.$refs.mid.clientWidth;

      document.onmouseup = this.onMouseup;
      document.onmousemove = this.onMousemove;
    },
    onMousemove:function(e){
        if(this.move.isMove){
          switch(this.move.type){
            case 'left':
              var x1 = e.pageX - this.move.pageX;
              var left = this.move.size + x1;
              if(left < 0){
                left = 4;
              }
              else if(left > this.move.size + this.move.w){
                left = this.move.size + this.move.w
              }
              this.mleft = left;
              break;
            case 'right':
              var x2 = e.pageX - this.move.pageX;
              var right = this.move.size - x2;
              if(right < 0){
                right = 4;
              }
              else if(right > this.move.size + this.move.w){
                right = this.move.size + this.move.w
              }
              this.mright = right;
              break;
            case 'top':
              var y = e.pageY - this.move.pageY;
              var top = this.move.size + y;
              if(top < 0){
                top = 4;
              }
              else if(top > this.move.size + this.move.h){
                top = this.move.size + this.move.h
              }
              this.mtop = top;
              break;
            case 'bottom':
              var y2 = e.pageY - this.move.pageY;
              var bottom = this.move.size - y2;
              if(bottom < 0){
                bottom = 4;
              }
              else if(bottom > this.move.size + this.move.h){
                bottom = this.move.size + this.move.h
              }
              this.mbottom = bottom;
              break;
          }
        }
    },
    onMouseup:function(){
      this.move.isMove = false;
      document.onmousemove = document.onmouseup = null;
    }
  }
}
</script>
<style lang="less">
@import './index.less';
</style>
