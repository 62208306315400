window.$baiduAk = 'dPHQKHyFl1GmMBjGVbVCSs6GSrswTuPQ'
export default function loadBMap() {
    return new Promise(function(resolve, reject) {
        if (typeof window.BMap !== 'undefined') {
            resolve(window.BMap)
            return
        }
        window.onBMapCallback = function() {
            resolve(window.BMap)
        }
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://api.map.baidu.com/api?v=3.0&ak=${window.$baiduAk}&callback=onBMapCallback`
        script.onerror = reject
        document.head.appendChild(script)
    })
}