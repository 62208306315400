<template>
    <div class="l-edit-table" >
        <el-table
            :data="dataSource"

            style="width: 100%"
            size = "mini"
            :height="height"
            :max-height="maxHeight"
            :header-cell-style="{padding:'4px 0'}"

            :stripe="stripe"
            :border="border"
            :fit="fit"
            :show-header="showHeader"
            :highlight-current-row="highlightCurrentRow"
            :current-row-key="currentRowKey"
            :row-key="rowKey"

            :row-class-name="rowClassName"
            :cell-class-name="cellClassName"
            :header-row-class-name="headerRowClassName"
            :header-cell-class-name="headerCellClassName"

            :default-expand-all="defaultExpandAll"
            :expand-row-keys="expandRowKeys"

            :default-sort="defaultSort"

            :tooltip-effect="tooltipEffect"

            :show-summary="showSummary"
            :sum-text="sumText"
            :summary-method="summaryMethod"

            :span-method="spanMethod"

            :select-on-indeterminate="selectOnIndeterminate"

            :indent="indent"
            :lazy="lazy"
            :load="load"
            :tree-props="treeProps"

            @select="select"
            @select-all="selectAll"
            @selection-change="selectionChange"
            @cell-mouse-enter="cellMouseEnter"
            @cell-mouse-leave="cellMouseLeave"
            @cell-click="cellClick"
            @cell-dblclick="cellDblclick"
            @row-click="rowClick"
            @row-contextmenu="rowContextmenu"
            @row-dblclick="rowDblclick"
            @header-click="headerClick"
            @header-contextmenu="headerContextmenu"
            @sort-change="sortChange"
            @filter-change="filterChange"
            @current-change="currentChange"
            @header-dragend="headerDagend"
            @expand-change="expandChange"

            ref="learunTable"
            >
            <el-table-column
                        v-if="isShowNum"
                        label="序号"
                        type="index"
                        
                        :index="indexMethod"
                        >
            </el-table-column>  
            <template v-for="(col,index) in columns" >
              <el-table-column 
                :key="index"
                :prop="col.prop"
                :label="col.label"
                :align="col.align" 
                :width="col.width"
                :min-width="col.minWidth"
                :header-align="col.headerAlign"
                v-if="!col.isHidden"
                :render-header="renderHeader"
                >
                <template
                  v-slot:header="{column}"
                >
                  <span v-if="isRequired(column.property)" style="color:red">*</span><span>{{column.label}}</span>
                </template>
                <template v-slot="scope" >
                  <slot v-bind="scope" :name="col.prop">{{scope.row[col.prop]}}</slot>
                </template>
              </el-table-column>
            </template>
            <slot></slot>
            <el-table-column v-if="isRemoveBtn" align="center" width="40" >
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    type="text"
                    style="color: #f56c6c;"
                    icon="el-icon-delete"
                    v-if="hasDeleteBtn(scope.row)"
                    @click="handleDelete(scope.$index, scope.row)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="isAddBtn" class="l-edit-table--addbtn" @click="handleAdd" >
            <el-button type="text" size="mini" icon="el-icon-plus" >{{$t(addBtnText)}}</el-button>
        </div>
    </div>
</template>
<script>
import tableEvent from '@util/tableEvent';
export default {
  name:'l-edit-table',
  mixins: [tableEvent()],
  props: {
    columns:{
      type: Array,
      default: () => []
    },
    dataSource:{
      type: Array,
      default: () => []
    },
    height:{
      type:[String,Number]
    },
    maxHeight:{
      type:[String,Number]
    },
    stripe:{
      type:Boolean,
      default: false
    },
    border:{
      type:Boolean,
      default: false
    },
    fit:{
      type:Boolean,
      default: true
    },
    showHeader:{
      type:Boolean,
      default: true
    },
    highlightCurrentRow:{
      type:Boolean,
      default: false
    },

    currentRowKey:[String,Number],
    rowKey:[String,Function],

    rowClassName:[String,Function],
    cellClassName:[String,Function],
    headerRowClassName:[String,Function],
    headerCellClassName:[String,Function],

    defaultExpandAll:Boolean,
    expandRowKeys:{
      type: Array
    },

    defaultSort:Object,

    tooltipEffect:String,

    showSummary:{
      type:Boolean,
      default: false
    },
    sumText:{
      type:String,
      default: "合计"
    },
    summaryMethod:Function,

    spanMethod:Function,

    selectOnIndeterminate:{
      type:Boolean,
      default: true
    },

    indent:{
      type:Number,
      default: 16
    },
    lazy:Boolean,
    load:Function,
    treeProps:Object,

    isPage:{
      type:Boolean,
      default: false
    },
    pageSizes:Array,
    pageTotal:{
      type:Number,
      default:0
    },

    isShowNum:{
      type:Boolean,
      default: true
    },
    isMultiSelect:Boolean,


    addBtnText:{
      type:String,
      default:'新增一行'//新增一行
    },
    isAddBtn:{
      type:Boolean,
      default:true
    },
    isRemoveBtn:{
      type:Boolean,
      default:true
    },
    required:{
      type:Boolean,
      default:false
    },
    filtreDeleteBtn:Function
  },
  data () {
    return {
    };
  },
  mounted () {
  },
  computed:{
  },
  methods:{
    indexMethod(index) {
      return index +1;
    },
    handleDelete(index,row){
      this.$emit('deleteRow',{index:index,row:row});
    },
    handleAdd(){
      this.$emit('addRow');
    },
    hasDeleteBtn(row){
      if(this.filtreDeleteBtn){
        return this.filtreDeleteBtn(row)
      }
      else {
        return true;
      }
    },
    isRequired(property){
      let myColumn = this.columns.find(t=>t.prop == property)
      return myColumn.required
    },
    validate(){
      let res = true
      if(this.required){
        if(this.dataSource.length == 0){
          this.$message({
              type: 'error',
              message: this.$t(`请添加表格数据`)
          })
          return false
        }
      }

      this.dataSource.forEach((row,index) => {
        this.columns.forEach(col=>{
          if(!col.isHidden){
            if(col.required && this.$validatenull(row[col.prop])){
              this.$message({
                  type: 'error',
                  message: this.$t(`请输入第${index + 1}行-${col.label}`)
              })
              res = false
              return false
            }

            if(col.patterns && col.patterns.length > 0){
              col.patterns.forEach(pattern=>{
                  if(pattern.reg && !this.$validatenull(row[col.prop]) && !new RegExp(pattern.reg.substring(1,pattern.reg.length-1)).test(row[col.prop])){
                    this.$message({
                        type: 'error',
                        message: this.$t(`${pattern.msg}第${index + 1}行-${col.label}`)
                    })
                    res = false
                    return false
                  }
              })
              if(!res){
                return false
              }
            }
          }
        })
        if(!res){
          return false
        }
      })
      return res
    }
  }
}
</script>
<style lang="less">
  @import './index.less';
</style>
