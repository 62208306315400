<template>
  <div class="l-radio">
    <el-radio-group v-model="value2" :size="size" >
      <template v-for="(item,index) in options" >
          <el-radio v-if="!isButton" :disabled="disabled" :border="border" :key="index" :label="item[valueKey]">{{item[labelKey]}}</el-radio>
          <el-radio-button v-else :key="index" :disabled="disabled"  :label="item[valueKey]">{{item[labelKey]}}</el-radio-button>
      </template>
    </el-radio-group>
  </div>
</template>
<script>
export default {
  name:'l-radio',
  props: {
      value:{
        type:[String,Number],
        default:''
      },
      labelKey:{
          type:String,
          default:'label'
      },
      valueKey:{
          type:String,
          default:'value'
      },
      options:{
          type:Array,
          default:()=>[]
      },
      /*defaultValue:{
          type:Number,
          default:0
      },*/
      disabled:Boolean,
      size: String,
      border:Boolean,
      isButton:Boolean,
  },
  data () {
    return {
    };
  },
  created(){
    //this.initValue();
    //this.setValue();
  },
  mounted () {
  },
  watch: {
    /*options:{
      handler () {
        this.setValue();
      }
    }*/
  },
  computed:{
    value2:{
      get(){
        return this.value
      },
      set(val){
        this.$emit('input', val)

        const obj = this.options.find(t=>t[this.valueKey] == val)
        this.$emit('change', obj)
      }
    }
  },
  methods:{
    /*setValue(){
      // 数据变更后改变数据
      if(this.options.length && !this.value2 && this.defaultValue > -1){
        this.value2 = this.options[this.defaultValue][this.valueKey];
      }
    }*/
  }
}
</script>
<style lang="less">
  @import './index.less';
</style>
