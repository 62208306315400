<template>
    <div  
        class="l-value-to-label"
        >
        <el-button @click="handleClick" v-if="['file','img'].includes(type) && value" type="text">查看</el-button>
        <i v-else-if="['icon'].includes(type) && value" :class="value" ></i>
        <el-rate v-bind="$deepClone(setting)" v-else-if="['rate'].includes(type) && value" :value="value" disabled></el-rate>
        <el-switch v-bind="$deepClone(setting)" v-else-if="['switch'].includes(type) && value" :value="value" disabled
            :activeValue="toValueType(setting.valueType,setting.activeValue)"
            :inactiveValue="toValueType(setting.valueType,setting.inactiveValue)"
        ></el-switch>
        <el-progress v-else-if="['slider'].includes(type) && value" :percentage="value" :format="progressFormat" ></el-progress>
        <div v-else-if="['color'].includes(type) && value" class="l-color-block" :style="{'background-color':value}"  ></div>
        <template v-else>
            {{$t(label)}}
        </template>

        <l-dialog
            v-if="['file','img'].includes(type) && value"
            :title="['file'].includes(type)?'文件预览':'图片预览'"
            :visible.sync="dialogVisible"
            :hasBtns="false"
        >
            <div class="l-rblock" style="overflow: auto;padding:8px;" >
                 <l-upload
                    v-if="['file'].includes(type)"
                    :readonly="true"
                    :value="value"
                ></l-upload>
                <l-upload
                    v-else
                    :readonly="true"
                    :value="value"
                    listType="picture-card"
                ></l-upload>
            </div>
        </l-dialog>

    </div>
</template>

<script>
export default {
    name:'l-value-to-label',
    props:{
        value:[String,Number,Boolean],
        type:{
            type:String,
            default:''
        },
        code:{
            type:String,
            default:''
        },
        valueKey:String,
        labelKey:{
            type: String,
            default:'f_FullName'
        },
        options:{
            type:Array,
            default:()=>[]
        },
        format:String,
        setting:{
            type:Object,
            default:()=>{}
        }
    },
    data () {
        return {
            //loading:false,
            dialogVisible:false
        }
    },
    computed:{
        label({
            value,
            type,
            code,
            valueKey,
            labelKey,
            options,
            format,
            lr_dataItem,
            lr_dataItemName,
            lr_dataSourceData,
            lr_dataSourceName,
            lr_companyList,
            lr_department,
            lr_role,
            lr_post,
            lr_users,
            lr_areas
            }){
            if(window.$validatenull(value)){
                return ''
            }
            switch(type){
                case 'mydata':{
                    const values = value + ''
                    const valueList = values.split(',')
                    const res = []
                    valueList.forEach(v =>{
                        const vItem = options.find(t=>t.value == v)
                        if(vItem && vItem.label){
                            res.push(vItem.label)
                        }
                    })
                    return String(res)
                }
                case 'dataItem':
                    if(lr_dataItem){
                        return lr_dataItemName(lr_dataItem[code],value)
                    }
                    else{
                        return ''
                    }
                case 'dataSource':
                    if(lr_dataSourceData){
                        return lr_dataSourceName(lr_dataSourceData[code],value,valueKey,labelKey)
                    }
                    else{
                        return ''
                    }
                case 'datetime':
                    return window.$formatDate(value,format || 'yyyy-MM-dd hh:mm:ss')
                case 'company':
                    if(lr_companyList){
                        return (lr_companyList.find(t=>t.f_CompanyId == value) || {} )[labelKey] || ''
                    }
                    else{
                        return ''
                    }
                case 'department':
                    if(lr_department){
                        return (lr_department[value] || {})[labelKey] || ''
                    }
                    else{
                        return ''
                    }
                case 'role':
                    if(lr_role){
                        return (lr_role[value] || {})[labelKey] || ''
                    }
                    else{
                        return ''
                    }
                case 'post':
                    if(lr_post){
                        return (lr_post[value] || {})[labelKey] || ''
                    }
                    else{
                        return ''
                    }
                case 'user':
                    if(lr_users){
                        const userIdList = value.split(',')
                        const names= []
                        userIdList.forEach(userId => {
                            if(lr_users[userId]){
                                names.push(lr_users[userId].f_RealName)
                            }
                        })
                        return String(names)  
                    }
                    else{
                        return ''
                    }
                case 'areas':
                    if(lr_areas && value){
                        const areaList = value.split(',')
                        if(areaList.length == 3){
                            const names= []
                            const one = lr_areas['0'] || []
                            const one1 = one.find(t=>t.f_AreaCode == areaList[0])
                            if(one1){
                                names.push(one1.f_AreaName)
                            }
                            const two = lr_areas[areaList[0]] || []
                            const two2 = two.find(t=>t.f_AreaCode == areaList[1])
                            if(two2){
                                names.push(two2.f_AreaName)
                            }
                            const three = lr_areas[areaList[1]] || []
                            const three3 = three.find(t=>t.f_AreaCode == areaList[2])
                            if(three3){
                                names.push(three3.f_AreaName)
                            }

                            return String(names)  
                        }

                        return ''
                    }
                    else{
                        return ''
                    }
                default:
                    return value
            }
        }
    },
    created(){
        this.init()
    },
    methods:{
        async init(){
            if(this.type && this.value){
                //this.loading = true
                switch(this.type){
                    case 'dataItem':
                        this.lr_loadDataItem && await this.lr_loadDataItem(this.code) 
                        break;
                    case 'dataSource':
                        this.lr_loadDataSourceData && await this.lr_loadDataSourceData(this.code) 
                        break;
                    case 'company':
                        this.lr_loadCompanys && await this.lr_loadCompanys()
                        break
                    case 'department':
                        this.lr_loadDepartment && await this.lr_loadDepartment(this.value)
                        break
                    case 'role':
                        this.lr_loadRole && await this.lr_loadRole(this.value)
                        break
                    case 'post':
                        this.lr_loadPost && await this.lr_loadPost(this.value)
                        break
                    case 'user':
                        this.lr_loadUsers && await this.lr_loadUsers(this.value)
                        break
                    case 'areas':
                        if(this.lr_loadAreas){
                            const areaList = this.value.split(',')
                            if(areaList.length == 3){
                                await Promise.all([
                                    this.lr_loadAreas(0),
                                    this.lr_loadAreas(areaList[0]),
                                    this.lr_loadAreas(areaList[1])
                                ])
                            }
                        }
                        break
                }
                //this.loading = false
            }
        },
        handleClick(){
            this.dialogVisible = true
        },
        toValueType(type,value){
            if(!this.$validatenull(value) && !this.$validatenull(type)){
                switch(type){
                    case 'boolean':
                        return value == 'true';
                    case 'string':
                        return value;
                    case 'number':
                        return Number(value);
                }
            }
        },
        progressFormat(percentage) {
            return percentage
        }
    }
}
</script>
<style lang="less">
.l-value-to-label{
    min-height: 24px;
}
</style>