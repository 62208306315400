<template>
    <el-input :value="value2" readonly="readonly"  suffix-icon="el-icon-time" ></el-input>    
</template>
<script>
export default {
    name:'l-createtime',
    props: {
        value:String
    },
    watch: {
        value:{
            handler(newVal){
                if(this.$validatenull(newVal)){
                    this.value2 = this.$getDayTime()
                }
                else{
                    this.value2 = this.$formatDate(newVal,'yyyy-MM-dd hh:mm:ss')
                }
            },
            immediate: true
        }
    },
    computed:{
        value2:{
            get(){
                return this.value
            },
            set(val){
                this.$emit('input',val)
            }
        }
    }
}
</script>