<template>
    <el-dialog
        :visible.sync="midVisible"
        :custom-class="theme == 'dark'?'l-dialog-dark':'l-dialog'"
       
        :append-to-body="true"
        :close-on-click-modal="false"
        :title="title"
        :before-close="beforeClose"
        :destroy-on-close="destroyOnClose"

        :width="myWidth"
        :top="top + 'px'"

        :show-close="showClose"


        @open="openDialog"
        @opened="openedDialog"
        @close="closeDialog"
        @closed="closedDialog"
        >
        <template #title >
            <slot name="title" ></slot>
        </template>
        <div 
            v-loading="loading"
            :element-loading-text="$t(loadingText)" 
            :class="['l-dialog-window',{'l-dialog-window-hasBtns':hasBtns},{'l-dialog-window-hasSteps':isStep}]"  
            :style="{height:heightComputed + 'px'}" >
            <el-steps v-if="isStep" :active="stepActive" simple finish-status="success">
              <el-step v-for="(item,index) in steps" :key="index" :title="$t(item)"></el-step>
            </el-steps>

            <slot></slot>

            <div class="l-dialog-btns" v-if="hasBtns" >
                <slot name="btns" >
                    <el-button v-if="isStep" @click="prev" :plain="theme == 'dark'" size="mini" :disabled="stepPrevBtn" >{{$t('上一步')}}</el-button>
                    <el-button v-if="isStep" @click="next" :plain="theme == 'dark'" size="mini" :disabled="stepNextBtn" :loading="nextBtnLoading"  >{{$t('下一步')}}</el-button>
                    <el-button v-if="!isStep && showClose" :plain="theme == 'dark'" size="mini" @click="handleClose" >{{$t('取消')}}</el-button>
                    <el-button :plain="theme == 'dark'" size="mini" type="primary" @click="handleOk" :disabled="stepSaveBtn && isStep" >{{isStep?$t('完成'):$t('确定')}}</el-button>
                </slot>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
  name:'l-dialog',
  props: {
    title:String,
    visible:{
      type:Boolean,
    },
    width:{
      type: [String,Number],
      default: 600
    },
    height:{
      type: Number,
      default: 400
    },
    beforeClose:Function,
    destroyOnClose:{
      type: Boolean,
      default: false
    },
    hasBtns:{
      type:Boolean,
      default:true
    },
    showClose:{
      type: Boolean,
      default: true
    },

    isStep:{
      type:Boolean,
      default:false
    },
    stepActive:{
      type:Number,
      default:0
    },
    steps:{
      type:Array,
      default:()=>[]
    },
    validateSteps:Function,

    theme:{
      type:String,
      default:'default' // dark
    }
  },
  watch:{
    visible: {
        handler (n) {
            this.midVisible = n
        }
    },
  },
  data () {
    return {
      loading: false,
      loadingText:'',
      midVisible:false,
      nextBtnLoading:false,

      stepPrevBtn:true,
      stepNextBtn:false,
      stepSaveBtn:true,
    };
  },
  mounted () {
  },
  computed:{
    heightComputed(){
        let _height = this.height;
        if(_height + 40 > document.body.clientHeight){
            _height = document.body.clientHeight - 32 - 40;   
        }
        return _height;
    },
    top(){
        return (document.body.clientHeight - this.heightComputed - 40)/2
    },
    myWidth(){
      if(typeof this.width == 'string'){
        return this.width
      }
      else{
        return `${this.width}px`
      }
    },
    myStepActive:{
      get(){
        return this.stepActive
      },
      set(val){
        this.$emit('update:stepActive',val)
      }
    }
  },
  methods:{
    handleClose(){
        this.midVisible = false
        if(this.isStep){
          this.myStepActive = 0
          this.$nextTick(()=>{
            this.updateBtnState()
          })
        }
        //this.$emit('update:visible',false);
    },
    handleOk(){
      this.$emit('ok',this.showLoading,this.hideLoading);
    },
    closeDialog() {
      this.$emit('update:visible',false);
      this.$emit('close');
    },
    closedDialog(){
      this.$emit('closed');
    },
    openDialog(){
      this.$emit('open');
    },
    openedDialog(){
      this.$emit('opened',this.showLoading,this.hideLoading);
    },
    showLoading(text){
      this.loadingText = text || '保存数据中...';
      this.loading = true
    },
    hideLoading(){
      this.loading = false
    },


    // 带步骤的表单
    prev(){
      if(this.myStepActive > 0){
          this.myStepActive--
      }
      this.$nextTick(()=>{
        this.updateBtnState()
      })
    },
    async next(){
      if(await this.myValidateSteps()){
        if(this.myStepActive < (this.steps.length-1)){
          this.myStepActive++
        }
        this.$nextTick(()=>{
          this.updateBtnState()
        })
      }
    },
    updateBtnState(){
      this.stepSaveBtn = true
      this.stepNextBtn = true
      this.stepPrevBtn = true
      switch(this.myStepActive){
        case 0:
          this.stepNextBtn = false
          break;
        case this.steps.length-1:
          this.stepPrevBtn = false
          this.stepSaveBtn = false
          break;
        default:
          this.stepPrevBtn = false
          this.stepNextBtn = false
          break;
      }
    },
    async myValidateSteps(){
      this.nextBtnLoading = true
      if(this.validateSteps){
        const res = await this.validateSteps(this.stepActive)
        this.nextBtnLoading = false
        return res
      }
      this.nextBtnLoading = false
      return true
    },
  }
}
</script>
<style lang="less">
@import './index.less';
</style>
