<template>
<el-select 
  ref="main"
  class="l-select"
  :size="size"
  
  v-model="value2"
  :multiple="multiple"
  :value-key="valueKey"
  :disabled="disabled" 
  :placeholder="placeholder"
  :filterable="filterable"

  :filterMethod="filterMethod"
  :clearable="clearable"

  @change="handleChange"
  @clear="handleClear"
  >
    <el-option
        v-for="(item,index) in options"
        :key="index"
        :label="item[labelKey]"
        :value="item[valueKey]">
    </el-option>
</el-select>

</template>
<script>
import { validatenull } from '@util/validate';
export default {
  name:'l-select',
  props: {
    value:{},
    placeholder:String,
    labelKey:{
      type:String,
      default:'label'
    },
    valueKey:{
      type:String,
      default:'value'
    },
    size:String,
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled:Boolean,
    filterable:Boolean,
    filterMethod:Function,
    multiple:Boolean,
    clearable:{
      type:Boolean,
      default:true
    }
  },
  created(){
  },
  data () {
    return {
    };
  },
  mounted () {
  },
  computed:{
    value2:{
      get(){
        if(this.multiple){
            if(!validatenull(this.value)){
                return (this.value + '').split(',');
            }
            else{
                return [];
            }
        }
        else{
            return this.value;
        }
      },
      set(val){
        if(this.multiple){
          this.$emit('input', String(val));
        }
        else{
            this.$emit('input', val);
        }
        
      }
    }
  },
  methods:{
    handleChange(val){
      if(this.multiple){
        if(val.length == 0){
          this.$emit('change',undefined)
          return
        }

        const objs = []
        val.forEach(item => {
          const objone = this.options.find(t=>t[this.valueKey] == item)
          objs.push(objone)
        })
        this.$emit('change', objs)
      }
      else{ 
        if(val == ''){
          this.$emit('change',undefined)
          return
        }
        const obj = this.options.find(t=>t[this.valueKey] == val)
        this.$emit('change', obj)
      }
    },
    handleClear(){
      this.$emit('clear')
    }
  }
}
</script>
<style lang="less">
@import './index.less';
</style>
