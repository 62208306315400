import lrBMapSelect from './_src/lrBMapSelect.vue'

lrBMapSelect.install = function(Vue) {
  Vue.component(lrBMapSelect.name, lrBMapSelect)
}

export default lrBMapSelect



