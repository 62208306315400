<template>
<el-date-picker
    ref="main"
    class="l-time"
    :format="myformat"
    :valueFormat="myformat"
    :placeholder="placeholder"
    :startPlaceholder="startPlaceholder"
    :endPlaceholder="endPlaceholder"
    :readonly="readonly"
    :disabled="disabled"
    :editable="editable"
    :clearable="clearable"
    :size="size"
    :type="dateType"
    :align="align"
    :rangeSeparator="rangeSeparator"
    :defaultValue="defaultValue"


    v-model="value2"
  >
</el-date-picker>

</template>
<script>
import { validatenull } from '@util/validate';
export default {
  name:'l-date',
  props: {
    value:{},
    placeholder:String,
    startPlaceholder:String,
    endPlaceholder:String,
    readonly:Boolean,
    disabled:Boolean,
    editable:Boolean,
    clearable:{
        type:Boolean,
        default:true
    },
    size:String,
    dateType:{
        type:String,
        default:'date'
    },
    align:String,
    rangeSeparator:{
        type:String,
        default:'-'
    },
    defaultValue:Date,
   
    

    format:String,
   
    selectableRange:[String,Array],
   
    
  },
  created(){
  },
  data () {
      return {
      }
  },
  mounted () {
  },
  computed:{
      value2:{
          get(){
              if(this.isRange){
                  if(validatenull(this.value)){
                      return ["",""];
                  }
                  else{
                      return this.value.split(' - ');
                  }
              }
              else{
                  return this.value;
              }
          },
          set(newValue){
                if(this.isRange){
                    if(validatenull(newValue)){
                        this.$emit('input', newValue);
                    }
                    else{
                        this.$emit('input',`${newValue[0]} ${this.rangeSeparator} ${newValue[1]}`);
                    }
                   
                }
                else{
                    this.$emit('input', newValue);
                }

                this.$emit('change',newValue);
          }
      },
      myformat(){
          let format = ''
          switch(this.dateType){
                case 'datetime':
                    format = 'yyyy-MM-dd HH:mm:ss'
                    break
                case 'datetimerange':
                    format = 'yyyy-MM-dd HH:mm'
                    break
                case 'daterange':
                    format = 'yyyy-MM-dd'
                    break 
                case 'monthrange':
                    format = 'yyyy-MM'
                    break 
          }
          return this.format || format
          
      },
      isRange(){
          return ['datetimerange','daterange','monthrange'].includes(this.dateType)
      }
  },
  methods:{
      
  }
}
</script>
