<template>
    <l-tree-select
        v-model="selectValue"
        :options="myOptions"
        :placeholder="$t(placeholder)"
        :size="size"
        :disabled="disabled"

        @change="handleChange"
        >
    </l-tree-select>   
</template>

<script>

export default {
    name:'l-company-select',
    props: {
        value:{},
        size:String,
        placeholder:{
            type:String,
            default:'请选择'
        },
        disabled:Boolean
    },
    data () {
        return {
            
        }
    },
    computed:{
        selectValue:{
            get(){
                return this.value;
            },
            set(val){
                this.$emit('input',val)
            }
        },
        myOptions(){
            if(this.lr_companyList){
                return this.$toTree( this.lr_companyList,"f_CompanyId","f_ParentId","f_CompanyId","f_FullName")
            }
            else{
                return []
            }
        }
    },
    created(){
        this.lr_loadCompanys && this.lr_loadCompanys()
    },
    methods:{
        handleChange(val){
            this.$emit('change',val)
        }
    }
}
</script>