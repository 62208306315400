<template>
    <el-input :value="value2" readonly="readonly" ></el-input>    
</template>
<script>
export default {
    name:'l-code',
    props: {
        value:String,
        code:String,
    },
    watch: {
        value:{
            handler(newVal){
                if(this.$validatenull(newVal)){
                    this.getMyCode()
                }
            },
            immediate: true
        }
    },
    computed:{
        value2:{
            get(){
                return this.value
            },
            set(val){
                this.$emit('input',val)
            }
        }
    },
    methods:{
        async getMyCode(){
            if(!this.$validatenull(this.code) && this.lr_getCode){
                this.value2 = await this.lr_getCode(this.code)
            }
        }
    }
}
</script>